<template lang="pug">
	.default-modal
		.modal-header
			.modal-title {{ financeAdvertiser.legalEntity.name }} - {{ this.financeAdvertiser.month }}/{{ financeAdvertiser.year }}
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			form.modal-body-inner(@submit.prevent="submit()")
				.row
					.col-sm-6
						.form-group
							label Month
							select.form-control(v-model="financeAdvertiser.month", :disabled="!is_new")
								option(v-for="o in monthOptions", :value="o.v") {{ o.t }}
					.col-sm-6
						.form-group
							label Year
							select.form-control(v-model="financeAdvertiser.year", :disabled="!is_new")
								option(v-for="o in yearOptions", :value="o.v") {{ o.v }}
				.row
					.col-sm-6
						.form-group
							label Status
							select.form-control(v-model="financeAdvertiser.status")
								option(:value="0") Draft
								option(:value="1") Open
								option(:value="2") Sent
								option(:value="3") Paid
								option(:value="4") Closed
								option(:value="5") Postponed
					.col-sm-6
						.form-group
							label Date Sent
							date-picker(v-model="financeAdvertiser.date_sent", :config="pickerConfig", @dp-change="updateDueDate")

				.row
					.col-sm-6
						.form-group
							label Estimated
								=' '
								small.form-text.text-muted.d-inline Before VAT
							number-input(v-model="financeAdvertiser.estimated_usd", prefix="$ ", readonly, fraction="2")
					.col-sm-6
						.form-group
							label Due Date
							date-picker(v-model="financeAdvertiser.due_date", :config="pickerConfig")
				.row
					.col-sm-6
						.form-group
							label Confirmed Price
								=' '
								small.form-text.text-muted.d-inline Before VAT
							number-input(v-model="financeAdvertiser.sum_usd", prefix="$ ", :readonly="financeAdvertiser.status >= 1", fraction="2")
					.col-sm-6
						.form-group
							label Payment Method
							select.form-control(v-model="financeAdvertiser.payment_method")
								option(value="wire") Wire Transfer
								option(value="paypal") Paypal
								option(value="payoneer") Payoneer
								option(value="offset") Offset
				.row
					.col-sm-6
						.form-group
							label Paid
								=' '
								small.form-text.text-muted.d-inline VAT Included
							number-input(v-model="financeAdvertiser.paid_usd", prefix="$ ", fraction="2")
					.col-sm-6
						.form-group
							label Date Paid
							date-picker(v-model="financeAdvertiser.date_paid", :config="pickerConfig")

				//.widget-secondary-title
					.row
						.col-sm-12
							.form-group
								label Invoices
								doc-list(:files="financeAdvertiser.invoice_files")

				.widget-secondary-title(v-if="!financeAdvertiser.receipt_url || newReceiptUploaded")
					.row
						.col-sm-8
							.form-group
								label Receipt
								doc-list(:files="newReceipt", :single="true", :cb="onReceiptReady")
						.col-sm-4
							.form-group
								label Receipt Num
								input.form-control(type="text", v-model="financeAdvertiser.receipt_num")


				.form-group
					label Comments
					textarea.form-control(v-model="financeAdvertiser.comments", rows="5")
				.form-group
					button.btn.btn-primary(type="submit") Save

</template>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'AdvertiserFinanceModal',
  props: ['financeAdvertiser', 'yearOptions', 'monthOptions', 'is_new'],
  data() {
    return {
      busy: false,
      newReceipt: [],
      newReceiptUploaded: false,
      pickerConfig: {
        format: 'DD/MM/YYYY',
        showClear: true,
        allowInputToggle: true,
        icons: {
          time: 'la la-clock',
          date: 'la la-calendar',
          up: 'la la-chevron-up',
          down: 'la la-chevron-down',
          previous: 'la la-chevron-left',
          next: 'la la-chevron-right',
          today: 'la la-screenshot',
          clear: 'la la-trash',
          close: 'la la-remove',
        },
      },
    };
  },
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      if (this.financeAdvertiser.status === 2 && !this.financeAdvertiser.date_sent) {
        /** sent with no date sent */
        return this.$ovNotify.error('Please fill in "date sent"');
      }
      if (this.financeAdvertiser.status === 3 && !this.financeAdvertiser.date_paid) {
        /** paid with no date paid */
        return this.$ovNotify.error('Please fill in "date paid"');
      }
      this.busy = true;
      try {
        let params = Vue.util.extend({}, this.financeAdvertiser);
        params.is_new = this.is_new;
        if (params.is_new) {
          params.identifier = `${params.legal_entity_id}-${params.month}-${params.year}`;
        }
        await this.$ovReq.post('finance/advertiser/save', params);
        this.$emit('close');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    updateDueDate() {
      if (this.financeAdvertiser.date_sent) {
        let dateSent = moment(this.financeAdvertiser.date_sent, 'DD/MM/YYYY');
        if (dateSent.isValid()) {
          this.financeAdvertiser.due_date = dateSent
            .add(this.financeAdvertiser.legalEntity.advertiser_net_terms, 'days')
            .toDate();
        }
      }
    },

    onReceiptReady(file) {
      this.newReceiptUploaded = true;
      this.financeAdvertiser.receipt_url = file.full_path;
      let recNumberMatch = file.name.match(/[0-9]{4,6}/g);
      if (recNumberMatch && recNumberMatch.length) {
        this.financeAdvertiser.receipt_num = recNumberMatch[0];
      }
    },
  },
};
</script>
