<template lang="pug">
	.default-modal
		.modal-header
			.modal-title Generate Receipt for {{ legal_entity.name }}
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			form.modal-body-inner(@submit.prevent="submit()")
				.ov-table-wrapper
					table.table.table-bordered.table-finance.no-hover
						thead
							tr
								th # Invoice
								th Month / Year
								th Confirmed
								th Total (VAT)
								th Paid
								th Method

						tbody
							tr(v-for="r in advertiser_records", v-bind:key="r.identifier")
								td \#{{ r.invoice_num }}
								td {{ r.month }} / {{ r.year }}
								td {{ r.sum_usd | toCurrency }}
								td {{ r.total_usd | toCurrency }}
								td {{ r.paid_usd | toCurrency }}
								td {{ r.payment_method }}

						thead(v-if="offset_records.length")
							tr
								th(colspan="7") Offset Records
						tbody
							tr(v-for="r in offset_records", v-bind:key="r.identifier", :class="{'row-selected':r._selected}")
								td.col-select
									a(href="javascript:void(0)", @click="toggleOffset(r)")
										i.la(:class="{'la-check-square':r._selected, 'la-stop':!r._selected}")
								td {{ r.month }} / {{ r.year }}
								td.negative {{ -r.confirmed_usd | toCurrency }}
								td.negative {{ -r.total_usd | toCurrency }}
								td &nbsp;
								td &nbsp;

						tfoot
							tr
								th(colspan="2") &nbsp;
								th {{ summary_sum_usd | toCurrency }}
								th {{ summary_total_usd | toCurrency }}
								th {{ summary_paid_usd | toCurrency }}
								th(colspan="1") &nbsp;

				.row
					.col-sm-4
						.form-group
							label Total Amount
							number-input(:value="summary_total_usd", prefix="$ ", readonly, fraction="2")
					.col-sm-4
						.form-group
							label Total Paid
							number-input(:value="summary_paid_usd", prefix="$ ", readonly, fraction="2")
					.col-sm-4
						.form-group
							label Date Paid
							input.form-control(type="text", :value="date_paid", disabled)

				p.clearfix &nbsp;

				.row
					.col-sm-12
						.d-flex.justify-content-between
							button.btn.btn-secondary(type="button", @click="$emit('close')")
								| Cancel
							button.btn.btn-primary(type="submit")
								| Confirm
								i.la.ml-1(:class="{'la-send':!busy, 'la-spinner la-spin': busy}")

</template>
<script>
import Vue from 'vue';

export default {
  name: 'ReceiptSummaryModal',
  props: ['legal_entity', 'advertiser_records', 'offset_records'],
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    toggleOffset(r) {
      Vue.set(r, '_selected', !r._selected);
    },

    async submit() {
      if (this.busy) {
        return;
      }
      try {
        let confirmed = await this.$bvModal
          .msgBoxConfirm(`Are you sure you want to generate a receipt for
				${this.legal_entity.name} for ${this.advertiser_records.length === 1 ? 'invoice ' : 'invoices '}
				${this.advertiser_records.map((r) => `#${r.invoice_num}`).join(', ')}
				${
          this.offset_records.filter((r) => r._selected).length > 0
            ? 'and for the ' +
              this.offset_records.filter((r) => r._selected).length +
              ' selected offset records?'
            : '?'
        }
					`);
        if (!confirmed) {
          return;
        }
        this.busy = true;
        let params = {
          legal_entity_id: this.legal_entity.id,
          advertiser_records: this.advertiser_records.join(','),
          offset_records: this.offset_records.filter((r) => r._selected).join(','),
        };
        await this.$ovReq.post('finance/advertiser/generateReceipt', params);
        this.busy = false;
        this.$ovNotify.success('Receipt was generated successfully');
        this.$emit('close');
      } catch (e) {
        this.busy = false;
        this.$ovNotify.error(e);
      }
    },
  },
  computed: {
    summary_sum_usd() {
      return (
        this.advertiser_records.reduce((sum, r) => {
          sum += r.sum_usd;
          return sum;
        }, 0) +
        this.offset_records
          .filter((r) => r._selected)
          .reduce((sum, r) => {
            sum -= r.confirmed_usd;
            return sum;
          }, 0)
      );
    },
    summary_total_usd() {
      return (
        this.advertiser_records.reduce((sum, r) => {
          sum += r.total_usd;
          return sum;
        }, 0) +
        this.offset_records
          .filter((r) => r._selected)
          .reduce((sum, r) => {
            sum -= r.total_usd;
            return sum;
          }, 0)
      );
    },
    summary_paid_usd() {
      return this.advertiser_records.reduce((sum, r) => {
        sum += r.paid_usd;
        return sum;
      }, 0);
      // + this.offset_records.filter(r => r._selected).reduce((sum, r) => {sum-=r.paid_usd; return sum}, 0);
    },
    date_paid() {
      return this.advertiser_records.length > 0 ? this.advertiser_records[0].date_paid : '';
    },
  },
};
</script>
<style lang="scss">
tr.row-selected {
  td.negative {
    color: #a00;
  }
}
</style>
