<template lang="pug">
	.default-modal
		.modal-header
			.modal-title Compose Email
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			form.modal-body-inner(@submit.prevent="submit()")
				.row
					.col-sm-12
						.form-group
							label To
							b-form-tags(v-model="data.to", placeholder="Add Recipient")
				.row
					.col-sm-12
						.form-group
							label BCC
							b-form-tags(v-model="data.bcc", placeholder="Add Recipient")
				.row
					.col-sm-12
						.form-group
							label Subject
							input.form-control(type="text", v-model="data.subject")
				.row
					.col-sm-12
						.form-group
							label Body
							textarea.form-control(v-model="data.body", rows="5")
				.row
					.col-sm-12
						.form-group
							label Attachments
							table.file-list
								tbody
									tr(v-for="(f, index) in data.attachment")
										td.col-name
											input.form-control(type="text", v-model="f.name", readonly)
										td.actions
											a.link-view(target="_blank", :href="f.full_path")
												i.la.la-search
											a.link-remove(href="javascript:void(0);", @click="removeFile(f, index)",
												v-b-tooltip.bottom.hover, title="remove from email")
												i.la.la-times

				.form-group
					button.btn.btn-primary(type="submit")
						| Send
						i.ml-1.la(:class="{'la-send':!busy, 'la-spinner la-spin': busy}")

</template>
<script>
import Vue from 'vue';

export default {
  name: 'AdvertiserEmailModal',
  props: ['data'],
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    removeFile(f, index) {
      this.data.attachment.splice(index, 1);
    },
    async submit() {
      if (this.busy) {
        return;
      }
      let conformationMsg =
        this.data.attachment.length > 0
          ? 'Send Email'
          : 'Are you sure you send this email with no attachments?';
      let confirmed = await this.$bvModal.msgBoxConfirm(conformationMsg);
      if (confirmed) {
        this.busy = true;
        try {
          let data = Vue.util.extend({}, this.data);
          await this.$ovReq.post('finance/advertiser/sendEmail', data);
          this.busy = false;
          this.$emit('close');
        } catch (e) {
          this.busy = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
table.file-list {
  width: 100%;
  border: none;
  td.col-name {
    width: 100%;
    .form-control {
      height: 30px;
    }
  }
  td.actions {
    white-space: nowrap;
    a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
      text-align: center;
      padding: 0;
      border-radius: 5px;
      background: #f6f6f6;
      margin: 0 1px;
      color: #aaa;
    }
  }
}
</style>
