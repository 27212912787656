<template lang="pug">
div.default-modal
  loading(:active.sync="busy", :is-full-page="false")
  .modal-header
    .modal-title Invoice Preview
    a.close(href="javascript:void(0);", @click="$emit('close')")
      i.la.la-times
  .modal-body
    .modal-body-inner
      .legal-entity-data
        p.mb-0
          b To: {{ legalEntity.name }}
        p Address: {{ legalEntity.address }}

      .items-data
        table.table.table-bordered.table-striped
          thead
            tr
              th Description
              th Unit Price
              th Quantity
              th Total Ex. Vat
              th VAT
              th Total Inc. Vat
          tbody
            tr(v-for="(item, index) in items" :key="index")
              td {{ item.description }}
              td {{ item.unitprice }}$
              td {{ item.quantity }}
              td {{ item.unitprice * item.quantity }}$
              td {{ legalEntity.vat ? '✅' : '❌' }}
              td {{ item.unitprice * item.quantity * (legalEntity.vat ? 1.17 : 1) }}$
            tr 
              td.text-right(colspan="3") 
                b Total
              td
                b {{ items.reduce((acc, item) => acc + item.unitprice * item.quantity, 0) }}$
              td
              td
                b {{ items.reduce((acc, item) => acc + item.unitprice * item.quantity * (legalEntity.vat ? 1.17 : 1), 0) }}$

      form(@submit.prevent="submit")
        .form-group
          .d-flex.gap-1
            label.mb-0 Comments
            a.btn.btn-action-default.p-0(v-if="!commentsAreEditable", @click="commentsAreEditable = true")
              i.la.la-1x.la-edit
            a.btn.btn-action-default.p-0(v-else, @click="commentsAreEditable = false")
              i.la.la-1x.la-close
          div(v-if="commentsAreEditable")
            textarea.form-control.mb-2(rows="3", v-model="formData.comments")
            .d-flex.gap-1.justify-content-end
              button.btn.btn-primary(type="button", @click="commentsAreEditable = false") Apply
              button.btn.btn-danger(type="button", @click="() => { commentsAreEditable = false; formData.comments = '' }") Clear
          div(v-else)
            .invoice-notes {{ formData.comments }}

        .invoice-notes.mb-2 {{ invoiceNotes }}

        .buttons.d-flex.gap-1.justify-content-end
          button.btn.btn-primary(type="submit") Generate Invoice
          button.btn.btn-danger(@click.prevent="$emit('close')") Cancel
</template>
<script>
import { numToMonth } from '../../lib/datetime';

export default {
  name: 'GenerateInvoiceModal',
  props: ['invoiceData'],
  data() {
    return {
      busy: false,
      commentsAreEditable: false,
      formData: {
        comments: '',
      }
    };
  },
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }

      if (!this.legalEntity.ext_id) {
        this.$ovNotify.error(`${this.legalEntity.name} is not associated to any iCount client!`,);
        return;
      }
      if (!this.legalEntity.vat && this.legalEntity.country === 'IL') {
        const confirmedVat = await this.$bvModal.msgBoxConfirm(
          `${this.legalEntity.name} is registered as an Israeli company but is VAT exempt. Generate anyway?`,
        );
        if (!confirmedVat) {
          return;
        }
      }

      const finalSum = this.items.reduce((acc, item) => acc + item.unitprice * item.quantity * (this.legalEntity.vat ? 1.17 : 1), 0);
      const confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to generate an invoice for ${this.legalEntity.name} for ${finalSum} USD?`,
      );
      if (!confirmed) {
        return;
      }

      this.busy = true;
      const params = {
        identifier: this.invoiceData.identifier,
        comments: this.formData.comments,
        items: this.items,
      };

      try {
        /** @type {{ invoice_url: string }} */
        const response = await this.$ovReq.post('finance/advertiser/generateInvoice', params);
        this.busy = false;
        this.$ovNotify.success('Invoice was generated successfully');
        window.open(response.invoice_url, '_blank');
        setTimeout(() => this.$emit('close'), 1000);
      } catch (e) {
        console.error(e);
        this.$ovNotify.error('Error occured during invoice generation!');
      } finally {
        this.busy = false;
      }
    },
  },
  computed: {
    legalEntity() {
      return this.invoiceData.legalEntity;
    },
    invoiceNotes() {
      return this.$store.state.user.invoiceNotes;
    },
    items() {
      const itemDate = `${numToMonth(this.invoiceData.month)} ${this.invoiceData.year}`;
      const items = [{
        description: `Mobile Campaign Summary - ${itemDate}`,
        unitprice: this.invoiceData.sum_usd,
        quantity: 1,
      }];
      return items;
    }
  },
};
</script>

<style lang="scss" scoped>
.invoice-notes {
  white-space: pre;
}
</style>