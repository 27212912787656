<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget
      .widget-header
        h1.title Advertisers Finance
        i.la.la-2x.la-filter.d-flex.align-items-center.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'advertisersFinance'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .form-group(v-if="!filters.useDatePicker")
              label Month
              select.form-control(v-model="filters.month")
                option(value="") - All -
                option(v-for="o in monthOptions", :value="o.v") {{ o.t }}
            .form-group(v-if="!filters.useDatePicker")
              label Year
              select.form-control(v-model="filters.year")
                option(value="") - All -
                option(v-for="o in yearOptions", :value="o.v") {{ o.v }}
            .form-group
              ov-date-time-picker(v-if="filters.useDatePicker", v-model="filters.datePicker")
            .form-group.fg-advertiser
              label Legal Entity
              multiselect(:multiple="true", :options="legalEntityOptions", v-model="filters.legal_entity_id",
                track-by="v", label="t", deselect-label="", select-label="")
            .form-group
              label Status
              multiselect(:multiple="true", :options="statusOptions", v-model="filters.status",
                track-by="v", label="t", deselect-label="", select-label="")
            //.form-group.no-label
              b-checkbox(type="checkbox", v-model="filters.with_invoices") Have Invoices
            .form-group.no-label
              b-checkbox(type="checkbox", v-model="filters.hide_closed") Hide Closed
            .form-group.no-label
              b-checkbox(type="checkbox", v-model="filters.useDatePicker") Date Picker


          .form-row-end
            .form-group
              label &nbsp;
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download.mr-2
                    | Export
                  b-dropdown-item(@click="getPaymentReport()", :disabled="!filters.month || !filters.year")
                    i.la.la-download.mr-2
                    | Payment Report
                  b-dropdown-item(@click="syncMonth()", :disabled="!filters.month || !filters.year")
                    i.la.la-refresh.mr-2
                    | Sync Month
                  b-dropdown-divider
                  b-dropdown-text Create new Record
                  b-dropdown-form
                    b-form-group
                      v-select(:options="legalEntityOptions", v-model.number="add_legal_entity", label="t")
                    button.btn.btn-primary(type="button", @click="addRecord()", :disabled="!add_legal_entity") Add

        p.clearfix &nbsp;

        .ov-table-wrapper
          .tabs.table-tabs
            ul.nav.nav-tabs
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{records.length}})
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='bucket', highlight: bucket.length > 0}", @click="selectedTab='bucket'") Selected
                  span.bucket-size.ml-1 ({{bucket.length}})

          table.table.table-bordered.table-finance.no-hover
            thead
              tr
                th
                  span
                    a(href="javascript:void(0);", @click="selectAll()")
                      i.la.la-check-square-o
                    =' | '
                  span
                    a(href="javascript:void(0);", @click="clearBucket()")
                      i.la.la-square-o
                th Month / Year
                th Status
                th Entity
                th Estimated
                th Confirmed
                th Total (VAT)
                th Paid
                th Method
                th Date Sent
                th Due Date
                th Paid On
                th Invoice
                th Receipt
                th Comments
                th
                  b-dropdown(text="Actions", size="sm", variant="link")
                    b-dropdown-item(href="#", @click="sendInvoicesFromBucket()", :disabled="!bucketLegalId")
                      i.la.la-send.mr-2
                      | Send Invoices
                    //b-dropdown-item(href="#", @click="generateReceiptFromBucket()", :disabled="!bucketLegalId")
                      i.la.la-file.mr-2
                      | Generate Receipt

            tbody
              tr(v-if="!records.length")
                td(colspan="16") No matching records were found

              tr(v-for="r in (selectedTab==='bucket' ? bucket : records)", v-bind:key="r.identifier",
                :class="{'due': r.status === 2 && r.is_due, 'unsent': r.status === 1 && !!r.invoice_url, 'row-selected': bucketHash[r.identifier]}")
                td.col-select(:class="{'bucket-row':bucketHash[r.identifier]}")
                  a(href="javascript:void(0)", @click="toggleBucket(r)")
                    i.la(:class="{'la-check-square':bucketHash[r.identifier], 'la-stop':!bucketHash[r.identifier]}")
                td
                  button.btn.btn-outline-dark(type="button", @click="setDateFilter(r.month, r.year)", style="width: 75px") {{ r.month }} / {{ r.year }}
                td
                  button.btn.badge.badge-pill.badge-default(v-if="r.status===0", type="button", @click="addStatusFilter(0)") Draft
                  button.btn.badge.badge-pill.badge-danger(v-if="r.status===1", type="button", @click="addStatusFilter(1)") Open
                  button.btn.badge.badge-pill.badge-warning(v-if="r.status===2", type="button", @click="addStatusFilter(2)") Sent
                  button.btn.badge.badge-pill.badge-info(v-if="r.status===3", type="button", @click="addStatusFilter(3)") Paid
                  button.btn.badge.badge-pill.badge-success(v-if="r.status===4", type="button", @click="addStatusFilter(4)") Closed
                  button.btn.badge.badge-pill.badge-primary(v-if="r.status===5", type="button", @click="addStatusFilter(5)") Postponed
                td
                  .d-flex.justify-content-between
                    .legal-entity
                      button.btn.btn-outline-dark(type="button", @click="addLegalFilter(r.legal_entity_id)") {{ r.legalEntity.common_name || r.legalEntity.name }}
                      .text-muted.pl-2(v-if="r.legalEntity.common_name") {{ r.legalEntity.name }}
                    router-link.btn.btn-default(:to="{name:'legal-entity-edit', params: {id:r.legal_entity_id}}", v-b-tooltip.hover.right, title="Edit")
                      i.la.la-pencil
                td
                  div {{ r.estimated_usd | toCurrency }}
                    i.la.ml-1(v-if="r.o_estimated_usd", :class="{'la-balance-scale-right': r.estimated_usd <= r.o_estimated_usd, 'la-balance-scale-left': r.estimated_usd > r.o_estimated_usd}", v-b-tooltip.hover, :title="'Offset $'+r.o_estimated_usd")
                td
                  div {{ r.sum_usd | toCurrency }}
                    i.la.ml-1(v-if="r.o_sum_usd", :class="{'la-balance-scale-right': r.sum_usd <= r.o_sum_usd, 'la-balance-scale-left': r.sum_usd > r.o_sum_usd}", v-b-tooltip.hover, :title="'Offset $'+r.o_sum_usd")
                    small.percentage-sm.ml-1(:class="{'danger': (r.sum_usd/r.estimated_usd) < 0.75 }", v-if="r.estimated_usd") ({{Math.round(100*(r.sum_usd/r.estimated_usd))}}%)
                  // div(v-if="r.o_sum_usd", v-b-tooltip.hover.left, :title="`offset: $${r.o_sum_usd}`") {{ r.sum_usd - r.o_sum_usd | toCurrency }}
                  //small.percentage-sm(:class="{'danger': ((r.sum_usd - r.o_sum_usd)/(r.estimated_usd - r.o_estimated_usd)) < 0.75 }", v-if="r.estimated_usd - r.o_estimated_usd !== 0") ({{Math.round(100*((r.sum_usd - r.o_sum_usd)/(r.estimated_usd - r.o_estimated_usd)))}}%)
                td(style="font-weight: bold")
                  div {{ r.total_usd | toCurrency }}
                  // div(v-if="r.o_total_usd", v-b-tooltip.hover.left, :title="`offset: $${r.o_total_usd}`") {{ r.total_usd - r.o_total_usd | toCurrency }}
                td {{ r.paid_usd | toCurrency }}
                td {{ r.payment_method }}
                td {{ r.date_sent }}
                td {{ r.due_date }}
                td {{ r.date_paid }}
                td
                  a.link-view(target="_blank", :href="r.invoice_url", v-if="r.invoice_url") {{ r.invoice_num || 'INV' }}
                td
                  a.link-view(target="_blank", :href="r.receipt_url", v-if="r.receipt_url") {{ r.receipt_num || 'REC' }}
                td {{ r.comments }}
                td.actions
                  button.btn.btn-secondary.mr-2(type="button", @click="editRecord(r)", title="Edit")
                    i.la.la-pencil
                  button.btn.btn-secondary.mr-2(type="button", @click="deleteRecord(r)", title="Delete")
                    i.la.la-trash
                  //button.btn.btn-primary.mr-2(type="button", @click="sendInvoice([r])", title="Send Invoice", v-if="r.invoice_url && r.status === 1")
                    i.la.la-send
                  button.btn.btn-info.mr-2(type="button", @click="openInvoicePreviewModal(r)", title="Generate Invoice", v-if="!r.invoice_url && r.status === 1")
                    i.la.la-file
                  //button.btn.btn-primary.mr-2(type="button", @click="generateReceipt([r])", title="Generate Receipt", v-if="!!r.invoice_num && !!r.paid_usd && !receipt_url")
                    i.la.la-file
            tfoot(v-if="summary && selectedTab === 'search'")
              tr
                th(colspan="4") &nbsp;
                th {{ summary.estimated_usd | toCurrency }}
                th {{ summary.sum_usd | toCurrency }}
                  small.percentage-sm.ml-1(:class="{'danger': (summary.sum_usd/summary.estimated_usd) < 0.75 }", v-if="summary.estimated_usd") ({{Math.round(100*(summary.sum_usd/summary.estimated_usd))}}%)
                th {{ summary.total_usd | toCurrency }}
                th {{ summary.paid_usd | toCurrency }}
                th(colspan="5") &nbsp;
            tfoot(v-if="bucket.length && selectedTab !== 'search'")
              tr
                th(colspan="4") &nbsp;
                th {{ bucketSummary.estimated_usd | toCurrency }}
                th {{ bucketSummary.sum_usd | toCurrency }}
                  small.percentage-sm.ml-1(:class="{'danger': (bucketSummary.sum_usd/bucketSummary.estimated_usd) < 0.75 }", v-if="bucketSummary.estimated_usd") ({{Math.round(100*(bucketSummary.sum_usd/bucketSummary.estimated_usd))}}%)
                th {{ bucketSummary.total_usd | toCurrency }}
                th {{ bucketSummary.paid_usd | toCurrency }}
                th(colspan="5") &nbsp;
</template>
<style lang="scss">
.table-finance {
  tbody {
    tr {
      &.due {
        background: lighten(#dc3545, 35) !important;
      }

      &.unsent {
        background: lighten(#ec9602, 35) !important;
      }

      td {
        .percentage-sm {
          margin-left: 5px;
          font-size: 80%;

          &.danger {
            color: #dc3545;
          }
        }
      }
    }
  }

  .badge {
    font-size: 12px;
  }
}

.v-select {
  width: 200px;

  .vs__dropdown-menu {
    font-size: 11px;

    li {
      padding: 3px;
    }
  }
}

.multiselect__content-wrapper {
  .multiselect__element {
    .multiselect__option {
      font-size: 10px;
    }
  }
}
</style>
<script>
import Vue from 'vue';
import AdvertiserFinanceModal from './AdvertiserFinanceModal';
import AdvertiserEmailModal from './AdvertiserEmailModal';
import ReceiptSummaryModal from './ReceiptSummaryModal';
import GenerateInvoiceModal from './GenerateInvoiceModal';
import moment from 'moment';

export default {
  name: 'FinanceAdvertisers',
  async beforeRouteEnter(to, from, next) {
    let legalEntityIdStr = '' + to.query.legal_entity_id;
    let legalEntityIdList = [];
    if (legalEntityIdStr) {
      legalEntityIdList = legalEntityIdStr
        .split(',')
        .map((id) => +id)
        .filter((id) => id);
    }
    let selectedLegalEntities = [];

    try {
      let legalEntityOptions = await Vue.ovData.legalEntity.getOptions(true);
      if (legalEntityIdList.length) {
        legalEntityOptions.forEach((r) => {
          if (legalEntityIdList.indexOf(r.v) > -1) {
            selectedLegalEntities.push(r);
          }
        });
      }
      next((vm) => {
        vm.legalEntityOptions = legalEntityOptions;
        if (legalEntityIdList.length) {
          vm.filters.legal_entity_id = selectedLegalEntities;
        }
        vm.filters.month = to.query.month || '';
        vm.filters.year = to.query.year || '';
        let statuses = to.query.status || [];
        if (typeof statuses === 'string') {
          statuses = [statuses];
        }
        statuses.forEach((s) => vm.addStatusFilter(+s));
        vm.loadData();
        return vm;
      });
    } catch (e) {
      next(false);
    }
  },
  data() {
    const defaultFilters = {
      status: [],
      year: '',
      month: '',
      legal_entity_id: [],
      with_invoices: false,
      hide_closed: true,
      useDatePicker: false,
      datePicker: {
        hideTZ: true,
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
    };
    return {
      areFiltersOpened: false,
      busy: false,
      defaultFilters,
      filters: { ...defaultFilters },
      add_legal_entity: null,
      legalEntityOptions: [],
      yearOptions: [{ v: 2019 }, { v: 2020 }, { v: 2021 }, { v: 2022 }, { v: 2023 }, { v: 2024 }],
      statusOptions: [
        { v: 0, t: 'draft' },
        { v: 1, t: 'open' },
        { v: 2, t: 'sent' },
        { v: 3, t: 'paid' },
        { v: 4, t: 'closed' },
        { v: 5, t: 'postponed' },
      ],
      monthOptions: [
        { v: 1, t: 'January' },
        { v: 2, t: 'February' },
        { v: 3, t: 'March' },
        { v: 4, t: 'April' },
        { v: 5, t: 'May' },
        { v: 6, t: 'June' },
        { v: 7, t: 'July' },
        { v: 8, t: 'August' },
        { v: 9, t: 'September' },
        { v: 10, t: 'October' },
        { v: 11, t: 'November' },
        { v: 12, t: 'December' },
      ],
      selectedTab: 'search',
      records: [],
      bucket: [],
      bucketHash: {},
      summary: { estimated_usd: 0, paid_usd: 0, sum_usd: 0, total_usd: 0 },
      bucketSummary: { estimated_usd: 0, paid_usd: 0, sum_usd: 0, total_usd: 0 },
      bucketLegalId: null,
    };
  },
  methods: {
    editRecord: function (r) {
      this.$modal.show(
        AdvertiserFinanceModal,
        {
          financeAdvertiser: Vue.util.extend({}, r),
          is_new: false,
          yearOptions: this.yearOptions,
          monthOptions: this.monthOptions,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            this.loadData();
          },
        },
      );
    },
    deleteRecord: function () {
      this.$ovNotify.error('Not implemented');
    },
    addRecord: async function () {
      if (!this.add_legal_entity) {
        return;
      }
      let financeAdvertiser = Vue.ovData.financeAdvertiser.newInstance();
      financeAdvertiser.legal_entity_id = this.add_legal_entity.v;
      financeAdvertiser.legalEntity = {
        name: this.add_legal_entity.t,
        id: this.add_legal_entity.v,
      };
      this.$modal.show(
        AdvertiserFinanceModal,
        {
          is_new: true,
          financeAdvertiser,
          yearOptions: this.yearOptions,
          monthOptions: this.monthOptions,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            this.loadData();
          },
        },
      );
    },
    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      let params = {
        do_export: doExport ? doExport : 0,
        status: this.filters.status.map((r) => r.v).join(','),
        legal_entity_id: this.filters.legal_entity_id.map((r) => r.v).join(','),
        with_invoices: this.filters.with_invoices ? 1 : 0,
        hide_closed: this.filters.hide_closed ? 1 : 0,
      };
      if (this.filters.useDatePicker) {
        params.date_start = moment(this.filters.datePicker.dateRange.startDate).format(
          'YYYY-MM-DD',
        );
        params.date_end = moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD');
      } else {
        params.year = this.filters.year;
        params.month = this.filters.month;
      }
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('finance/advertiser/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.summary = resp.summary;
          this.records = resp.records;
          /** update items in the bucket */
          this.records.forEach((r) => {
            if (this.bucketHash[r.identifier]) {
              for (let i = 0; i < this.bucket.length; i++) {
                if (this.bucket[i].identifier === r.identifier) {
                  this.bucket[i] = r;
                  break;
                }
              }
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async getPaymentReport() {
      if (this.busy) {
        return;
      }
      if (!this.filters.year || !this.filters.month) {
        return;
      }
      let params = {
        year: this.filters.year,
        month: this.filters.month,
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('finance/advertiser/getPaymentReport', { params });
        window.open(resp.path);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    addStatusFilter(statusValue) {
      let status = this.statusOptions.find((o) => o.v === statusValue);
      let idx = this.filters.status.indexOf(status);
      if (idx === -1) {
        this.filters.status.push(status);
      } else {
        this.filters.status.splice(idx, 1);
      }
    },

    addLegalFilter(legalValue) {
      let legalEntity = this.legalEntityOptions.find((o) => o.v === legalValue);
      let idx = this.filters.legal_entity_id.indexOf(legalEntity);
      if (idx === -1) {
        this.filters.legal_entity_id.push(legalEntity);
      } else {
        this.filters.legal_entity_id.splice(idx, 1);
      }
    },

    setDateFilter(month, year) {
      if (this.filters.month !== month || this.filters.year !== year) {
        this.filters.month = month;
        this.filters.year = year;
      } else {
        this.filters.month = '';
        this.filters.year = '';
      }
    },

    async sendInvoice(advRecords) {
      if (!advRecords || !advRecords.length || !advRecords[0].legalEntity) {
        return this.$ovNotify.error(`invalid action!`);
      }
      let legalEntityId = advRecords[0].legalEntity.id;
      let otherIds = advRecords.filter((a) => !a.legalEntity || a.legalEntity.id !== legalEntityId);
      if (otherIds.length > 0) {
        return this.$ovNotify.error(`you can only send invoices to one legal entity at a time`);
      }
      if (this.busy) {
        return;
      }
      this.busy = true;
      let params = {
        legal_entity_id: legalEntityId,
        selected_records: advRecords.map((r) => r.identifier).join(','),
      };
      try {
        let resp = await this.$ovReq.post('finance/advertiser/getInvoiceEmailInfo', params);
        this.busy = false;
        this.$modal.show(
          AdvertiserEmailModal,
          {
            data: {
              ...resp,
              to: resp.to.split(','),
              bcc: resp.bcc.split(','),
              // year           : r.year,
              // month          : r.month,
              legal_entity_id: params.legal_entity_id,
              identifiers: params.selected_records,
            },
          },
          {
            height: 'auto',
            scrollable: true,
          },
        );
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    openInvoicePreviewModal(r) {
      this.$modal.show(
        GenerateInvoiceModal,
        { invoiceData: r },
        { height: 'auto', },
        { 'before-close': () => this.loadData() },
      );
    },

    async sendInvoicesFromBucket() {
      this.sendInvoice(this.bucket);
    },

    async generateReceiptFromBucket() {
      this.generateReceipt(this.bucket);
    },

    async generateReceipt(advRecords) {
      if (!advRecords || !advRecords.length || !advRecords[0].legalEntity) {
        return;
      }
      if (!advRecords[0].legalEntity.ext_id) {
        return this.$ovNotify.error(
          `${advRecords[0].legalEntity.name} is not associated to any iCount client!`,
        );
      }
      let invalidRecords = advRecords.filter((r) => !r.invoice_num || !r.paid_usd);
      if (invalidRecords.length > 0) {
        return this.$ovNotify.error(
          `some of the selected records (${invalidRecords.length}) are missing required details for a receipt`,
        );
      }

      if (this.busy) {
        return;
      }

      let offsetResp = { records: [] };
      try {
        let params = {
          status: '2', // PAID
          legal_entity_id: advRecords[0].legalEntity.id,
          payment_method: 'offset',
        };
        offsetResp = await this.$ovReq.get('finance/publisher/getList', { params });
      } catch (e) {
        console.error(e);
      }

      this.$modal.show(
        ReceiptSummaryModal,
        {
          legal_entity: advRecords[0].legalEntity,
          advertiser_records: advRecords,
          offset_records: offsetResp.records.map((r) => ({ ...r, _selected: true })),
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            this.loadData();
          },
        },
      );
    },

    async syncMonth() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let params = {
        year: this.filters.year,
        month: this.filters.month,
      };
      try {
        await this.$ovReq.post('finance/advertiser/syncMonth', params);
        this.busy = false;
        await this.loadData();
        return this.$ovNotify.success('Synced successfully');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    updateLegalSelection() {
      let selectedLegalIdHash = this.bucket
        .map((r) => r.legal_entity_id)
        .reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {});
      console.log(selectedLegalIdHash);
      this.bucketLegalId =
        Object.keys(selectedLegalIdHash).length === 1 ? Object.keys(selectedLegalIdHash)[0] : null;
    },
    clearBucket() {
      this.bucket = [];
      this.bucketHash = {};

      Vue.set(this.bucketSummary, 'estimated_usd', 0);
      Vue.set(this.bucketSummary, 'paid_usd', 0);
      Vue.set(this.bucketSummary, 'sum_usd', 0);
      Vue.set(this.bucketSummary, 'total_usd', 0);
      this.bucketLegalId = null;
    },
    selectAll() {
      this.records.forEach((r) => {
        if (!this.bucketHash[r.identifier]) {
          Vue.set(this.bucketHash, r.identifier, true);
          this.bucket.push(r);
          Vue.set(
            this.bucketSummary,
            'estimated_usd',
            this.bucketSummary.estimated_usd + r.estimated_usd,
          );
          Vue.set(this.bucketSummary, 'paid_usd', this.bucketSummary.paid_usd + r.paid_usd);
          Vue.set(this.bucketSummary, 'sum_usd', this.bucketSummary.sum_usd + r.sum_usd);
          Vue.set(this.bucketSummary, 'total_usd', this.bucketSummary.total_usd + r.total_usd);
        }
      });
      this.updateLegalSelection();
    },
    toggleBucket(r) {
      if (this.bucketHash[r.identifier]) {
        Vue.set(this.bucketHash, r.identifier, false);
        for (let i = 0; i < this.bucket.length; i++) {
          if (this.bucket[i].identifier === r.identifier) {
            this.bucket.splice(i, 1);
            Vue.set(
              this.bucketSummary,
              'estimated_usd',
              this.bucketSummary.estimated_usd - r.estimated_usd,
            );
            Vue.set(this.bucketSummary, 'paid_usd', this.bucketSummary.paid_usd - r.paid_usd);
            Vue.set(this.bucketSummary, 'sum_usd', this.bucketSummary.sum_usd - r.sum_usd);
            Vue.set(this.bucketSummary, 'total_usd', this.bucketSummary.total_usd - r.total_usd);
            break;
          }
        }
      } else {
        Vue.set(this.bucketHash, r.identifier, true);
        this.bucket.push(r);
        Vue.set(
          this.bucketSummary,
          'estimated_usd',
          this.bucketSummary.estimated_usd + r.estimated_usd,
        );
        Vue.set(this.bucketSummary, 'paid_usd', this.bucketSummary.paid_usd + r.paid_usd);
        Vue.set(this.bucketSummary, 'sum_usd', this.bucketSummary.sum_usd + r.sum_usd);
        Vue.set(this.bucketSummary, 'total_usd', this.bucketSummary.total_usd + r.total_usd);
      }
      this.updateLegalSelection();
    },
  },
};
</script>
